import React from "react";

export default ({ orderId, data }) => {
  return (
    <span
      id={`supplier-${orderId}`}
      className={`editMenuClasser editMenuClasser-${orderId}`}
    >
      <h1>supplier</h1>
      <h1>supplier</h1>
      <h1>supplier</h1>
      <h1>supplier</h1>
      <h1>supplier</h1>
    </span>
  );
};

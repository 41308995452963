// SYSTEM_INFORMATION
export const GET_SYSTEM_INFORMATION_PENDING = 'GET_SYSTEM_INFORMATION_PENDING';
export const GET_SYSTEM_INFORMATION_SUCCESS = 'GET_SYSTEM_INFORMATION_SUCCESS';
export const GET_SYSTEM_INFORMATION_FAILURE = 'GET_SYSTEM_INFORMATION_FAILURE';

export const FETCH_PRODUCTS_PENDING = 'FETCH_PRODUCTS_PENDING';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const FETCH_PRODUCT_BY_ID_PENDING = 'FETCH_PRODUCT_BY_ID_PENDING';
export const FETCH_PRODUCT_BY_ID_SUCCESS = 'FETCH_PRODUCT_BY_ID_SUCCESS';
export const FETCH_PRODUCT_BY_ID_FAILURE = 'FETCH_PRODUCT_BY_ID_FAILURE';

export const UPDATE_PRODUCT_PENDING = 'UPDATE_PRODUCT_PENDING';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';

export const DELETE_PRODUCT_PENDING = 'DELETE_PRODUCT_PENDING';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';

export const CREATE_PRODUCT_SIZE_PENDING = 'CREATE_PRODUCT_SIZE_PENDING';
export const CREATE_PRODUCT_SIZE_SUCCESS = 'CREATE_PRODUCT_SIZE_SUCCESS';
export const CREATE_PRODUCT_SIZE_FAILURE = 'CREATE_PRODUCT_SIZE_FAILURE';

export const UPDATE_PRODUCT_SIZE_PENDING = 'UPDATE_PRODUCT_SIZE_PENDING';
export const UPDATE_PRODUCT_SIZE_SUCCESS = 'UPDATE_PRODUCT_SIZE_SUCCESS';
export const UPDATE_PRODUCT_SIZE_FAILURE = 'UPDATE_PRODUCT_SIZE_FAILURE';

export const DELETE_PRODUCT_SIZE_PENDING = 'DELETE_PRODUCT_SIZE_PENDING';
export const DELETE_PRODUCT_SIZE_SUCCESS = 'DELETE_PRODUCT_SIZE_SUCCESS';
export const DELETE_PRODUCT_SIZE_FAILURE = 'DELETE_PRODUCT_SIZE_FAILURE';

export const CREATE_PRODUCT_QUANTITY_PENDING =
  'CREATE_PRODUCT_QUANTITY_PENDING';
export const CREATE_PRODUCT_QUANTITY_SUCCESS =
  'CREATE_PRODUCT_QUANTITY_SUCCESS';
export const CREATE_PRODUCT_QUANTITY_FAILURE =
  'CREATE_PRODUCT_QUANTITY_FAILURE';

export const UPDATE_PRODUCT_QUANTITY_PENDING =
  'UPDATE_PRODUCT_QUANTITY_PENDING';
export const UPDATE_PRODUCT_QUANTITY_SUCCESS =
  'UPDATE_PRODUCT_QUANTITY_SUCCESS';
export const UPDATE_PRODUCT_QUANTITY_FAILURE =
  'UPDATE_PRODUCT_QUANTITY_FAILURE';

export const DELETE_PRODUCT_QUANTITY_PENDING =
  'DELETE_PRODUCT_QUANTITY_PENDING';
export const DELETE_PRODUCT_QUANTITY_SUCCESS =
  'DELETE_PRODUCT_QUANTITY_SUCCESS';
export const DELETE_PRODUCT_QUANTITY_FAILURE =
  'DELETE_PRODUCT_QUANTITY_FAILURE';

export const PRODUCT_IMAGE_UPLOAD_PENDING = 'PRODUCT_IMAGE_UPLOAD_PENDING';
export const PRODUCT_IMAGE_UPLOAD_SUCCESS = 'PRODUCT_IMAGE_UPLOAD_SUCCESS';
export const PRODUCT_IMAGE_UPLOAD_FAILURE = 'PRODUCT_IMAGE_UPLOAD_FAILURE';

export const GET_ATTRIBUTES_PENDING = 'GET_ATTRIBUTES_PENDING';
export const GET_ATTRIBUTES_SUCCESS = 'GET_ATTRIBUTES_SUCCESS';
export const GET_ATTRIBUTES_FAILURE = 'GET_ATTRIBUTES_FAILURE';

export const GET_ATTRIBUTES_BY_ID_PENDING = 'GET_ATTRIBUTES_BY_ID_PENDING';
export const GET_ATTRIBUTES_BY_ID_SUCCESS = 'GET_ATTRIBUTES_BY_ID_SUCCESS';
export const GET_ATTRIBUTES_BY_ID_FAILURE = 'GET_ATTRIBUTES_BY_ID_FAILURE';

export const UPDATE_ATTRIBUTES_PENDING = 'UPDATE_ATTRIBUTES_PENDING';
export const UPDATE_ATTRIBUTES_SUCCESS = 'UPDATE_ATTRIBUTES_SUCCESS';
export const UPDATE_ATTRIBUTES_FAILURE = 'UPDATE_ATTRIBUTES_FAILURE';

export const DELETE_ATTRIBUTES_PENDING = 'DELETE_ATTRIBUTES_PENDING';
export const DELETE_ATTRIBUTES_SUCCESS = 'DELETE_ATTRIBUTES_SUCCESS';
export const DELETE_ATTRIBUTES_FAILURE = 'DELETE_ATTRIBUTES_FAILURE';

export const GET_INVOICES_PENDING = 'GET_INVOICES_PENDING';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';

export const GET_INVOICE_BY_ID_PENDING = 'GET_INVOICE_BY_ID_PENDING';
export const GET_INVOICE_BY_ID_SUCCESS = 'GET_INVOICE_BY_ID_SUCCESS';
export const GET_INVOICE_BY_ID_FAILURE = 'GET_INVOICE_BY_ID_FAILURE';

export const UPDATE_INVOICE_SEGMENT_PENDING = 'UPDATE_INVOICE_SEGMENT_PENDING';
export const UPDATE_INVOICE_SEGMENT_SUCCESS = 'UPDATE_INVOICE_SEGMENT_SUCCESS';
export const UPDATE_INVOICE_SEGMENT_FAILURE = 'UPDATE_INVOICE_SEGMENT_FAILURE';

export const UPDATE_INVOICE_ITEM_SEGMENT_BY_ID_PENDING =
  'UPDATE_INVOICE_ITEM_SEGMENT_BY_ID_PENDING';
export const UPDATE_INVOICE_ITEM_SEGMENT_BY_ID_SUCCESS =
  'UPDATE_INVOICE_ITEM_SEGMENT_BY_ID_SUCCESS';
export const UPDATE_INVOICE_ITEM_SEGMENT_BY_ID_FAILURE =
  'UPDATE_INVOICE_ITEM_SEGMENT_BY_ID_FAILURE';

export const RE_ORDER_INVOICE_ITEM_PENDING = 'RE_ORDER_INVOICE_ITEM_PENDING';
export const RE_ORDER_INVOICE_ITEM_SUCCESS = 'RE_ORDER_INVOICE_ITEM_SUCCESS';
export const RE_ORDER_INVOICE_ITEM_FAILURE = 'RE_ORDER_INVOICE_ITEM_FAILURE';

export const DELETE_INVOICE_ITEM_PENDING = 'DELETE_INVOICE_ITEM_PENDING';
export const DELETE_INVOICE_ITEM_SUCCESS = 'DELETE_INVOICE_ITEM_SUCCESS';
export const DELETE_INVOICE_ITEM_FAILURE = 'DELETE_INVOICE_ITEM_FAILURE';

export const DELETE_INVOICE_PENDING = 'DELETE_INVOICE_PENDING';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE';

export const ORDER_INVOICE_IMAGE_UPLOAD_PENDING =
  'ORDER_INVOICE_IMAGE_UPLOAD_PENDING';
export const ORDER_INVOICE_IMAGE_UPLOAD_SUCCESS =
  'ORDER_INVOICE_IMAGE_UPLOAD_SUCCESS';
export const ORDER_INVOICE_IMAGE_UPLOAD_FAILURE =
  'ORDER_INVOICE_IMAGE_UPLOAD_FAILURE';

export const ORDER_INVOICE_IMAGE_DELETE_PENDING =
  'ORDER_INVOICE_IMAGE_DELETE_PENDING';
export const ORDER_INVOICE_IMAGE_DELETE_SUCCESS =
  'ORDER_INVOICE_IMAGE_DELETE_SUCCESS';
export const ORDER_INVOICE_IMAGE_DELETE_FAILURE =
  'ORDER_INVOICE_IMAGE_DELETE_FAILURE';

export const INVOICE_PAYMENT_PENDING = 'INVOICE_PAYMENT_PENDING';
export const INVOICE_PAYMENT_SUCCESS = 'INVOICE_PAYMENT_SUCCESS';
export const INVOICE_PAYMENT_FAILURE = 'INVOICE_PAYMENT_FAILURE';

export const GET_SYSTEM_SETTINGS_PENDING = 'GET_SYSTEM_SETTINGS_PENDING';
export const GET_SYSTEM_SETTINGS_SUCCESS = 'GET_SYSTEM_SETTINGS_SUCCESS';
export const GET_SYSTEM_SETTINGS_FAILURE = 'GET_SYSTEM_SETTINGS_FAILURE';

export const UPDATE_SYSTEM_SETTINGS_PENDING = 'UPDATE_SYSTEM_SETTINGS_PENDING';
export const UPDATE_SYSTEM_SETTINGS_SUCCESS = 'UPDATE_SYSTEM_SETTINGS_SUCCESS';
export const UPDATE_SYSTEM_SETTINGS_FAILURE = 'UPDATE_SYSTEM_SETTINGS_FAILURE';

export const FETCH_CUSTOMERS_PENDING = 'FETCH_CUSTOMERS_PENDING';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

export const FETCH_CUSTOMER_PROFILE_PENDING = 'FETCH_CUSTOMER_PROFILE_PENDING';
export const FETCH_CUSTOMER_PROFILE_SUCCESS = 'FETCH_CUSTOMER_PROFILE_SUCCESS';
export const FETCH_CUSTOMER_PROFILE_FAILURE = 'FETCH_CUSTOMER_PROFILE_FAILURE';

export const UPDATE_CUSTOMER_PROFILE_PENDING =
  'UPDATE_CUSTOMER_PROFILE_PENDING';
export const UPDATE_CUSTOMER_PROFILE_SUCCESS =
  'UPDATE_CUSTOMER_PROFILE_SUCCESS';
export const UPDATE_CUSTOMER_PROFILE_FAILURE =
  'UPDATE_CUSTOMER_PROFILE_FAILURE';

export const FETCH_SUPPLIERS_PENDING = 'FETCH_SUPPLIERS_PENDING';
export const FETCH_SUPPLIERS_SUCCESS = 'FETCH_SUPPLIERS_SUCCESS';
export const FETCH_SUPPLIERS_FAILURE = 'FETCH_SUPPLIERS_FAILURE';

export const FETCH_SUPPLIER_PROFILE_PENDING = 'FETCH_SUPPLIER_PROFILE_PENDING';
export const FETCH_SUPPLIER_PROFILE_SUCCESS = 'FETCH_SUPPLIER_PROFILE_SUCCESS';
export const FETCH_SUPPLIER_PROFILE_FAILURE = 'FETCH_SUPPLIER_PROFILE_FAILURE';

export const UPDATE_SUPPLIER_PROFILE_PENDING =
  'UPDATE_SUPPLIER_PROFILE_PENDING';
export const UPDATE_SUPPLIER_PROFILE_SUCCESS =
  'UPDATE_SUPPLIER_PROFILE_SUCCESS';
export const UPDATE_SUPPLIER_PROFILE_FAILURE =
  'UPDATE_SUPPLIER_PROFILE_FAILURE';

export const FETCH_STAFF_PENDING = 'FETCH_STAFF_PENDING';
export const FETCH_STAFF_SUCCESS = 'FETCH_STAFF_SUCCESS';
export const FETCH_STAFF_FAILURE = 'FETCH_STAFF_FAILURE';

export const FETCH_STAFF_PROFILE_PENDING = 'FETCH_STAFF_PROFILE_PENDING';
export const FETCH_STAFF_PROFILE_SUCCESS = 'FETCH_STAFF_PROFILE_SUCCESS';
export const FETCH_STAFF_PROFILE_FAILURE = 'FETCH_STAFF_PROFILE_FAILURE';

export const UPDATE_STAFF_PROFILE_PENDING = 'UPDATE_STAFF_PROFILE_PENDING';
export const UPDATE_STAFF_PROFILE_SUCCESS = 'UPDATE_STAFF_PROFILE_SUCCESS';
export const UPDATE_STAFF_PROFILE_FAILURE = 'UPDATE_STAFF_PROFILE_FAILURE';

export const FETCH_PROFILE_PENDING = 'FETCH_PROFILE_PENDING';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const UPDATE_PROFILE_PENDING = 'UPDATE_PROFILE_PENDING';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
